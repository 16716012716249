<template>
	<div id="mainpage" class="home flex flex-col min-h-screen leading-tight">

		<SiteHeader />

		<section class="mt-[84px] py-24 bg-center bg-cover mainbanner-section lg:py-12">
			<div class="max-w-screen-xl px-4 mx-auto">
				<div class="lg:items-center flex flex-wrap -mx-4 overflow-hidden">
					<div class="lg:w-3/5 lg:text-left w-full px-4 text-center">
						<h3 class="lg:text-left lg:text-5xl text-2xl text-white">Experienced</h3>
						<h2 class="font-semibold text-white text-3xl mb-2 lg:mb-4 lg:text-left lg:text-[4rem] lg:leading-tight">
							Syariah Lawyers <span class="inline-block">At Your Service</span></h2>
						<h3 class="lg:text-left lg:text-5xl mb-6 text-2xl text-white">Attorneys For The People</h3>
						<div class="lg:block hidden pt-1"></div>
						<div
							class="p-4 bg-white border-2 border-white rounded lg:py-6 lg:pl-6 lg:pr-24 md:inline-block bg-[#FFFFFFC0] text-sm text-left lg:text-xl space-y-4 lg:space-y-6">
							<p>Divorces can be difficult &amp; navigating Syariah Law can be challenging without the right, patient
								guidance.</p>
							<p>We, here at Netto &amp; Magin LLC, aim to ease that burden and give you a better understanding on your
								journey.</p>
						</div>
					</div>
					<div class="lg:w-2/5 lg:flex hidden w-full px-4">
						<div class="w-full p-8 text-center bg-white rounded">
							<h3 class="mb-6 text-2xl font-medium">Book your Free Consultation<br>with us Today!</h3>
							<ContactForm />
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="about-us" class="text-center py-12 bg-[#E9EEF1] lg:py-20">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-3 lg:text-3xl lg:mb-4">Why Choose Netto &amp; Magin LLC</h3>
				<p class="lg:mb-12 mb-8 leading-normal">It is beneficial for you to engage a Syariah divorce lawyer if you are
					not familiar with the procedures of divorce. By doing so, you will be able to focus on your child/family
					needs, your physical and mental well-being (which is very important in these trying times, in order for you to
					be able to make wise, sound and reasonable decisions) while we represent you and help you to focus solely on
					the legal aspect (i.e drafting and preparing of all legal documents and necessary attendance at court).</p>
				<div class="lg:items-stretch flex flex-wrap justify-center -mx-4 overflow-hidden">
					<div class="md:w-1/2 lg:w-1/3 w-full px-4">
						<div class="lg:p-10 lg:mb-0 lg:h-full px-6 py-8 mb-4 bg-white rounded">
							<img class="icon-set mx-auto mb-4" src="@/assets/img/icon-01.png" width="100" height="100">
							<h4 class="lg:text-xl mb-4 text-lg font-semibold">Experienced <span class="block">Syariah Lawyers</span>
							</h4>
							<p class="lg:text-base text-sm leading-normal">Our firm has been around for over 40 years, and has been
								actively involved in Syariah matters for over 35 years. We will support and guide you through your
								Syariah divorce. We put our clients at ease with efficient problem-solving approaches on all syariah
								matters.</p>
						</div>
					</div>
					<div class="md:w-1/2 lg:w-1/3 w-full px-4">
						<div class="lg:p-10 lg:mb-0 lg:h-full px-6 py-8 mb-4 bg-white rounded">
							<img class="icon-set mx-auto mb-4" src="@/assets/img/icon-02.png" width="100" height="100">
							<h4 class="lg:text-xl mb-4 text-lg font-semibold">Free Consultation <span class="block">Tailored To
									Individual Needs</span></h4>
							<p class="lg:text-base text-sm leading-normal">Our specialised lawyers offer a free consultation tailored
								to help identify how we can assist your individual needs, and also offer payments by way of instalments.
								Throughout this challenging period, we will continue to provide you with the support and be available to
								assist you via videoconferencing, teleconferencing and email.</p>
						</div>
					</div>
					<div class="md:w-1/2 lg:w-1/3 w-full px-4">
						<div class="lg:p-10 lg:mb-0 lg:h-full px-6 py-8 mb-4 bg-white rounded">
							<img class="icon-set mx-auto mb-4" src="@/assets/img/icon-03.png" width="100" height="100">
							<h4 class="lg:text-xl mb-4 text-lg font-semibold">Reliable And <span class="block">Affordable
									Assistance</span></h4>
							<p class="lg:text-base text-sm leading-normal">It is important for you to know your rights before making
								ANY MAJOR DECISIONS. We pride ourselves in providing reliable and affordable assistance through your
								trying times; Our dedicated team of lawyers and their support team are happy to aid you with
								correspondences after regular office hours.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="lg:py-20 py-12 overflow-hidden">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="text-center font-semibold text-xl text-[#0074C8] mb-8 lg:text-3xl lg:mb-12">What Our Client Say About
					Us</h3>
				<div class="px-4 leading-normal">
					<carousel :autoplay="true" :loop="true" :autoplayTimeout="5000" :speed="1000" :per-page="1"
						:mouse-drag="false" :pagination-padding="8" :navigation-enabled="true" :pagination-size="8"
						pagination-active-color="#0074C8" pagination-color="#e9eef1">
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>After being assigned three different lawyers for my divorce case, I am very grateful for having Ms.
									Roqiyah for my appeal.</p>
								<p>She actually went through all my documents with care and concern, giving valuable inputs and
									recommendations.</p>
								<p>Ms. Roqiyah addressed all my queries patiently, and was well-prepared for the hearing. She exudes
									calmness and kindness, and I am indebted to her for the result of the appeal.</p>
								<p>Thank you so much Ms. Roqiyah for everything.</p>
								<p>Jazakillah khayran barakAllahu feek</p>
								<p><span class="font-bold">Syahirah — Syariah Appeal</span></p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>One-stop total solution provider for legal matters! especially for a family and divorce matters. I am
									convinced why this law firm has been given perfect score all the while. Ms. Roqiyah Begum is
									responsive and would go the extra mile to offer sound advice and alternative option. After speaking
									with Ms. Roqiyah Begum, there is no need to seek second opinion.</p>
								<p>You can never find a lawyer that have really help to understand matter easily, responsive and always
									goes to the extra mile.</p>
								<p>Ms. Roqiyah Begum is experience, dedicated and always respond even how busy she is.</p>
								<p>Her knowledge in family / legal matter and respond help me to lesser stress n get things done easily
									and reasonable as well. Her Explanation is easy yet professional. I will not hesitate to recommend my
									friend to go to her..</p>
								<p>Wish that I could give her 6-star!</p>
								<p><span class="font-bold">Mr Ummer — Syariah Divorce</span></p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>Going through a divorce was never easy but having Roqiyah Awan from Netto &amp; Magin by my side
									proved otherwise. I was fortunate and grateful that our path crossed. Apart from being an analytical,
									attentive and empathetic listener, i valued the insights, guidance and confidence she gave to keep me
									stay focused and positive. She was very responsive and always there come rain or shine. My anxiety was
									totally supressed despite having a failed mediation. I truly felt that I was in good hands. Albeit the
									lengthy proceedings in the courts, I was constantly updated on the progress of my case. She went above
									and beyond, spared no efforts and with her utmost dedications, I was awarded care and control of my
									children. I had a fair share of the matrimonial assets too. The feelings were priceless!</p>
								<p><span class="font-bold">Client A — Syariah</span></p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>I've engaged Miss Roqiyah in helping me with my will management through a FB page as well as my legal
									matters between me and my ex-husband.</p>
								<p>I'm really satisfied with her service as she goes beyond her area of focus and stands a lot from my
									point of view when I explained my situation to her.</p>
								<p>She accommodates a lot to my needs and when the case ended, she still continued to reach out to check
									in on my and listen to the troubles I have.</p>
								<p>I always thought that this would be a service relationship, but Miss Roqiyah has outdone herself in
									all areas. I would highly recommend her everyone as it's rare to find someone like her.</p>
								<p><span class="font-bold">Client B — Syariah</span></p>
							</div>
						</slide>
						<slide class="flex items-center px-4">
							<div class="bg-[#D9D9D9] p-4 rounded space-y-4">
								<p>.... Roqiyah had been meticulous in handling my case. At times, she goes beyond her job scope, in
									giving me advice. She understood my plight and answered my doubts immediately. I learned a handful of
									legal procedure from her. Thank you so much, Roqiyah for your unconditional and sincere help during my
									bad times</p>
								<p><span class="font-bold">Client C - Syahirah — Syariah Appeal (LAB)</span></p>
							</div>
						</slide>
					</carousel>
				</div>
			</div>
		</section>

		<section id="our-lawyers"
			class="bg-consultation section lawyer-section leading-normal text-white bg-center bg-cover">
			<div class="lg:space-y-12 max-w-screen-xl px-4 py-16 mx-auto space-y-8">
				<h3 class="lg:text-3xl text-xl font-semibold text-center">Our Syariah Team</h3>
				<div class="lg:flex-nowrap gap-x-16 gap-y-8 flex flex-wrap items-center">
					<div class="flex-1 w-full">
						<img class="lg:w-full h-auto rounded" src="@/assets/img/lucy-netto.jpg" alt="Lucy Netto" width="359"
							height="456">
					</div>
					<div class="lg:w-7/12 lg:space-y-12 w-full space-y-8">
						<h4 class="lg:text-2xl text-xl font-semibold">Lucy Netto</h4>
						<div class="space-y-4">
							<p class="lg:text-base text-sm leading-normal">Lucy Netto graduated from the University of London and was
								admitted to the Honourable Society of Lincoln’s Inn in 1982 and admitted as an Advocate & Solicitor of
								the Supreme Court of Singapore in the same year.</p>
							<p class="lg:text-base text-sm leading-normal">She was actively involved in legal practice from 1982 to
								1994 before leaving to lecture law. Since her return, she has been in continuous active practice. She
								has handled property matters, conveyancing, family matters, Probate and personal injury cases. She also
								does a great deal of solicitors work such as preparing contracts, wills, Powers of Attorney, amongst
								various other fields of law.</p>
							<p class="lg:text-base text-sm leading-normal">She represents clients in the Court of Appeal, High Court,
								State Courts, and Family Justice Courts on matters involving significant issues of law. She has also
								represented clients in both the Syariah Court and Appeal Board. She continues to be actively involved in
								solicitors’ and general litigation work.</p>
						</div>
					</div>
				</div>
				<hr class="lg:hidden border-gray-500">
				<div class="lg:flex-nowrap gap-x-16 gap-y-8 flex flex-wrap items-center">
					<div class="lg:order-2 flex-1 order-1 w-full">
						<img class="lg:w-full h-auto rounded" src="@/assets/img/roqiyah-begum.jpg" alt="Roqiyah Begum" width="359"
							height="456">
					</div>
					<div class="lg:w-7/12 lg:space-y-12 lg:order-1 order-2 w-full space-y-8">
						<h4 class="lg:text-2xl text-xl font-semibold">Roqiyah Begum</h4>
						<div class="space-y-4">
							<p class="lg:text-base text-sm leading-normal">Roqiyah Begum graduated from the University of Sheffield in
								2009 and was admitted as an Advocate & Solicitor of the Supreme Court of Singapore in 2012.</p>
							<p class="lg:text-base text-sm leading-normal">Roqiyah practices general litigation with a focus in the
								areas of, Family Law and Probate matters, also specializing in Syariah Law. She also deals with
								proceedings and applications involving custody of children, maintenance, and Personal Protection Orders
								(PPO).</p>
							<p class="lg:text-base text-sm leading-normal">She has been with the firm since her practice training
								contract commenced and is actively involved in Legal Clinics. Roqiah also resolutely deals with the
								Syariah Courts as a recognised member of the Islamic legal community.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="services" class="text-center py-12 lg:py-20 bg-[#E9EEF1]">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-8 lg:text-3xl lg:mb-12">List of Services Under Syariah Law
				</h3>
				<!-- <p class="lg:text-lg lg:mb-12 leading-normal">The consultation is for us to better understand you, and you to better understand how we can assist:</p> -->
				<div class="relative flex flex-wrap -mx-4">
					<div class="lg:pl-4 lg:pr-14 lg:w-11/12 lg:absolute right-0 flex w-full">
						<img class="rounded" src="@/assets/img/img-steps.jpg" width="1458" height="970" />
					</div>
					<div class="lg:w-6/12 lg:mt-0 relative w-full px-4 -mt-12">
						<div class="lg:pt-12 lg:space-y-4 space-y-6">

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">1</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Syariah Divorce</h4>
										<p class="lg:text-base text-sm leading-normal">Including issues of grounds of divorce, pronouncement
											of talak, custody, care and control and access, division of matrimonial assets (including
											matrimonial home), nafkah iddah and mutaah, outstanding emas kahwin, outstanding marriage expenses
										</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">2</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Appeal to MUIS Appeal Board</h4>
										<p class="lg:text-base text-sm leading-normal">From decision of the Syariah Court</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">3</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Variation Application</h4>
										<p class="lg:text-base text-sm leading-normal">Variation of Syariah Court Orders</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">4</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Muslim Wills</h4>
										<p class="lg:text-base text-sm leading-normal">Drafting of Muslim Wills (Wasiat)</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">5</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Inheritance Certificate</h4>
										<p class="lg:text-base text-sm leading-normal">Obtaining of Inheritance Certificate (Fara-Id)</p>
									</div>
								</div>
							</div>

							<div class="p-6 text-left bg-white rounded">
								<div class="flex">
									<h5 class="text-5xl text-[#0074C8] leading-none lg:text-6xl">6</h5>
									<div class="mt-1 ml-4">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Probate and Intestacy Matters</h4>
										<p class="lg:text-base text-sm leading-normal">Relating to Muslim estates and obtaining Grant of
											Probate/Letters of Administration</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="lg:py-20 py-12 text-center bg-white">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="font-semibold text-xl text-[#0074C8] mb-3 lg:text-3xl lg:mb-4">Process of Syariah Divorce</h3>
				<p class="lg:text-lg lg:mb-12 mb-8 leading-normal">Whether “talak” has been pronounced or not, the man or the
					woman may file for a divorce in Syariah Court. <span class="lg:block">Briefly there are 5 steps in the divorce
						procedure:</span></p>
				<div class="flex flex-wrap -mx-4">
					<div class="w-full px-4">
						<div class="lg:space-y-4 space-y-6">

							<div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Registration &amp; Mandatory Counselling
										</h4>
										<p class="lg:text-base text-sm leading-normal">To kick start the divorce process, the spouse wishing
											to apply for divorce in Syariah Court must first submit a Registration Form. This form is
											downloadable from the Syariah Court’s website. It is available in both English and Malay. Upon
											receiving the Form, parties will be notified to attend the Marriage Counselling Programme at one
											of the appointed community agencies. This is a mandatory administration of counselling.</p>
									</div>
								</div>
							</div>

							<div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Commencement of Divorce Proceedings in
											Syariah Court</h4>
										<p class="lg:text-base text-sm leading-normal">If counselling fails, the case file will then be sent
											back to Syariah Court. The Applicant will then have to draft, file and serve the documents on
											their spouse personally or through their lawyer. The deadline to file the Originating Summons is 6
											months from the date of the final Marriage Counselling Programme session.</p>
									</div>
								</div>
							</div>

							<div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Mediation</h4>
										<p class="lg:text-base text-sm leading-normal">Upon filing of the Originating Summons, parties will
											be notified to attend Mediation session(s) at Syariah Court. The purpose of the mediation session
											is to explore reconciliation options. However, if parties do not wish to reconcile, the mediator
											will assist parties to reach a settlement on the divorce and ancillary issues. If a settlement is
											reached, your spouse and yourself will attend before the President of Syariah Court for a consent
											order to be recorded. Upon the Court’s approval of the settlement, the divorce proceedings shall
											conclude.</p>
									</div>
								</div>
							</div>

							<div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Pre-Trial Conference</h4>
										<p class="lg:text-base text-sm leading-normal">In the event the mediation fails, the case will be
											adjourned for a Pre-Trial Conference (“PTC”). The objective of the PTC is to prepare parties for
											divorce hearing(s). Directions will be given at the PTC for parties to file the necessary
											documents. There may be several PTCs depending on the complexity of your case. </p>
									</div>
								</div>
							</div>

							<div class="p-6 bg-[#E9EEF1] rounded text-left">
								<div class="flex">
									<div class="">
										<h4 class="lg:text-lg mb-3 font-semibold leading-normal">Hearing</h4>
										<p class="lg:text-base text-sm leading-normal">Upon hearing the divorce and ancillary matters, the
											Syariah Court will deliver its judgment, either immediately or on another date. The divorce
											proceedings conclude once the judgment is delivered. It is important for parties to understand
											that there may be several hearings, before the Court delivers its judgment.</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="bg-consultation lg:py-20 py-12 text-center text-white bg-center bg-cover">
			<div class="max-w-screen-xl px-4 mx-auto">
				<h3 class="lg:text-3xl mb-4 text-xl font-semibold">Unlike a civil divorce in the Family Justice Courts, the
					Syariah Court does not make any maintenance order in respect of wife (during the marriage) or the children. In
					Syariah Court only two types of financial provision may be granted to the wife (i.e Nafkah Iddah and Mutaah).
				</h3>
				<h4 class="lg:text-xl sm:px-0 px-6 leading-normal">After receiving the orders made by the Learned President, if
					either party is dissatisfied with any of the orders, an appeal can be raised to the Syariah Appeal Board
					within 30 days from the date of the Order of Court.</h4>
			</div>
		</section>

		<section class="leading-normal bg-[#E9EEF1] section faqs-section">
			<div class="lg:space-y-12 max-w-screen-xl px-4 py-16 mx-auto space-y-8">
				<h3 class="font-semibold text-xl text-[#0074C8] lg:text-3xl text-center">FAQs About Syariah Law</h3>
				<div class="border-t border-black">
					<div class="border-b border-black">
						<button @click="accordions.faq_1=!accordions.faq_1;"
							class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="icon-show w-6 mr-1" v-show="!accordions.faq_1">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show"
										viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd" />
									</svg>
								</div>
								<div class="icon-hide w-6 mr-1" v-show="accordions.faq_1">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide"
										viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z" />
									</svg>
								</div>
							</div>
							May a husband make a request for divorce by way of Khuluk?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px]" v-show="accordions.faq_1">
							<p>No, as the act of Khuluk may only be initiated by the wife, as it is a form of divorce by way of
								redemption; as such, it may only be requested by the husband. It is to be noted that consent from both
								parties is require for Khuluk to take place.</p>
						</div>
					</div>

					<div class="border-b border-black">
						<button @click="accordions.faq_2=!accordions.faq_2;"
							class="flex lg:items-center w-full py-[10px] text-[21px] font-medium text-left leading-[1.19]">
							<div class="accordion-icon text-[#0074C8]">
								<div class="icon-show w-6 mr-1" v-show="!accordions.faq_2">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-show"
										viewBox="0 0 100 100">
										<path d="M100 46H54V0h-8v46H0v8h46v46h8V54h46z" fill-rule="evenodd" />
									</svg>
								</div>
								<div class="icon-hide w-6 mr-1" v-show="accordions.faq_2">
									<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" id="icon-svg-hide"
										viewBox="0 0 1250 100">
										<path d="M1250 0v100H0V0z" />
									</svg>
								</div>
							</div>
							How is Mutaah calculated?
						</button>
						<div class="mt-4 mb-6 lg:pl-[30px]" v-show="accordions.faq_2">
							<p>Mutaah is calculated based on the length of the marriage, as well as the financial means (including but
								not limited to the stocks, investments, properties, and CPF funds) of the husband. This is not similar
								to the notion of the husband having to pay maintenance (Nafkah Iddah) to the wife or for the kids
								thereafter.</p>
						</div>
					</div>

				</div>
			</div>
		</section>

		<section id="contact-us" class="section contact-section leading-normal bg-white">
			<div class="lg:space-y-12 max-w-screen-xl px-4 py-16 mx-auto space-y-8">
				<h3 class="font-semibold text-xl text-[#0074C8] lg:text-3xl text-center">Contact Us</h3>
				<div class="gap-y-8 flex flex-wrap -mx-4">
					<div class="lg:w-7/12 w-full px-4">
						<p class="lg:text-xl mb-8 text-center">Book your Free Consultation with us Today!</p>
						<ContactForm />
					</div>
					<div class="lg:w-5/12 w-full px-4">
						<div class="bg-[#E9EEF1] px-4 py-8 h-full flex flex-col justify-center items-center gap-y-2 text-center">
							<img class="w-auto h-[50px]" src="@/assets/img/icon-phone.png" alt="Phone" width="73" height="74">
							<a class="lg:text-2xl mb-4 text-lg font-medium" href="tel:+6594688285"><span>+65 9468 8285</span></a>
							<img class="w-auto h-[50px]" src="@/assets/img/icon-whatsapp.png" alt="WhatsApp" width="89" height="90">
							<a class="lg:text-2xl mb-4 text-lg font-medium"
								href="https://wa.me/+6594688285?text=I%20would%20like%20to%20enquire" target="_blank">+65 9468 &zwj;8285</a>
							<p>1 Coleman Street, #10-13, The Adelphi Singapore 179803</p>
						</div>
					</div>
				</div>
			</div>
		</section>


		<!-- <SiteFooter /> -->

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
// import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'index-page',
	components: {
		SiteHeader,
		// SiteFooter,
		ContactForm,
		Carousel,
		Slide,
	},
	data() {
		return {
			isOpen: 0,
			// isTop: true,
			accordions: {
				faq_1: 0,
				faq_2: 0,
			},
		}
	},
	// mounted () {
	// 	// document.addEventListener('scroll', this.handleScroll, {passive: true});
	// },
	// methods: {
	// 	handleScroll() {
	// 		this.isTop = window.scrollY < 200;
	// 	},
	// },
}
</script>

<style scoped>
	section {
		scroll-margin-top: 57px;
	}
	.mainbanner-section {
		background-image: url('~@/assets/img/herobanner.jpg');
		background-position-x: 25%;
		@apply bg-cover lg:bg-center bg-no-repeat;
	}
	.bg-consultation {
		background-image: url('~@/assets/img/bg-consultation.jpg');
	}
	/* @screen lg {
		.mainbanner-section {
			background-size: 1580px auto !important;
		}
	} */
	.list-header li {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(201,138,15,1)'/%3E%3C/svg%3E");
		background-position: left top;
		background-repeat: no-repeat;
		padding-left: 24px;
	}
	@screen lg {
		section {
			scroll-margin-top: 70px;
		}
		.list-header li {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(201,138,15,1)'/%3E%3C/svg%3E");
			padding-left: 40px;
		}
	}
</style>