<template>
	<header id="site-header" class="top-0 z-20 w-full text-black" :class="isTop ? 'absolute bg-white':'fixed shadow-md'">
		<div class="" :class="isTop ? 'Xpy-4 Xlg:py-6':'Xpy-2 bg-white duration-200'">
			<div class="max-w-screen-xl px-4 mx-auto">
				<div class="md:justify-between md:flex-nowrap gap-y-4 lg:gap-y-8 flex flex-wrap items-center justify-center"
					:class="isTop ? 'h-auto py-4 lg:h-[84px] lg:py-0':'h-[57px] lg:h-[70px]'">

					<router-link to="/" class="justify-between lg:justify-start items-center w-full gap-x-[1.75rem] md:w-auto"
						:class="isTop ? 'block':'hidden lg:flex'">
						<img class="object-contain w-auto mx-auto duration-200" :class="isTop ? 'h-10 lg:h-auto':'h-[36px]'"
							src="@/assets/img/logo.png" alt="Netto &amp; Magin Logo" width="443" height="50">
					</router-link>

					<div class="lg:flex-nowrap flex flex-wrap justify-center border-white" :class="{'divide-x': !isTop}">
						<div class="md:px-4 gap-x-2 lg:gap-x-4 flex px-3">
							<img class="w-auto h-8" :class="isTop ? 'lg:h-[50px]' : 'lg:h-10'" src="@/assets/img/icon-whatsapp.png"
								alt="WhatsApp" width="89" height="90">
							<div class="md:block flex items-center">
								<div class="md:block hidden text-xs" v-show="isTop">Text Or Whatsapp Us At</div>
								<a class="lg:text-2xl text-lg font-semibold"
									href="https://wa.me/+6594688285?text=I%20would%20like%20to%20enquire" target="_blank">9468
									&zwj;8285</a>
							</div>
						</div>
						<div class="md:px-4 gap-x-2 lg:gap-x-4 flex px-3">
							<img class="w-auto h-8" :class="isTop ? 'lg:h-[50px]' : 'lg:h-10'" src="@/assets/img/icon-phone.png"
								alt="Phone" width="73" height="74">
							<div class="md:block flex items-center">
								<div class="md:block hidden text-xs" v-show="isTop">Call Us At</div>
								<a class="lg:text-2xl text-lg font-semibold" href="tel:+6594688285"><span>9468 8285</span></a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 84;
			// if (window.pageYOffset >= 84) {
			// 	if (this.isTop) this.isTop = false;
			// } else if ( document.documentElement.scrollTop < 84 ) {
			// 	if (!this.isTop) this.isTop = true;
			// }
		},
	},
}
</script>

<style scoped>

</style>
